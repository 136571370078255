.color-scheme {
  display: flex;
  height: 80px;

  &-block {
    display: grid;
    place-items: center;
    width: 20%;
    height: 100%;
  }

  &-label {
    display: flex;
    align-items: center;
    column-gap: 3px;
    cursor: pointer;

    @include over-tablet-medium {
      column-gap: 12px;
    }

    svg {
      @include over-tablet-medium {
        height: 24px;
        width: 24px;
      }
    }

    p {
      font-family: $primary-font;
      font-weight: 500;
      font-size: 10px;
      color: #ffffff;

      @include over-tablet-medium {
        font-size: 24px;
        line-height: 34px;
      }
    }
  }
}

.white-back {
  p {
    color: #333;
  }
}
